@font-face {
  font-family: "Avant Garde";
  src: url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.woff2")
      format("woff2"),
    url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.woff")
      format("woff"),
    url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.ttf")
      format("truetype"),
    url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.eot?#iefix")
      format("embedded-opentype");

  font-display: swap;
}

@font-face {
  font-family: "Source Sans";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Regular.woff2")
      format("woff2"),
    url("./assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "Source Sans";
  font-style: italic;
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Italic.woff2")
      format("woff2"),
    url("./assets/fonts/SourceSansPro/SourceSansPro-Italic.ttf")
      format("truetype");
}

@font-face {
  font-family: "Source Sans";
  font-weight: 600;
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-SemiBold.woff2")
      format("woff2"),
    url("./assets/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf")
      format("truetype");
}

/* For Rocket */
@font-face {
  font-family: "AvantGardeGothic";
  src: url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.woff2")
      format("woff2"),
    url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.woff")
      format("woff"),
    url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.ttf")
      format("truetype"),
    url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.eot?#iefix")
      format("embedded-opentype");

  font-display: swap;
}

@font-face {
  font-family: "SourceSansPro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Regular.woff2")
      format("woff2"),
    url("./assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "SourceSansPro";
  font-style: italic;
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Italic.woff2")
      format("woff2"),
    url("./assets/fonts/SourceSansPro/SourceSansPro-Italic.ttf")
      format("truetype");
}

@font-face {
  font-family: "SourceSansPro";
  font-weight: 600;
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-SemiBold.woff2")
      format("woff2"),
    url("./assets/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf")
      format("truetype");
}

/* Hiding number input arrows

Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hiding number input arrows

Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
