.themed-checkbox + .checkbox-label,
.themed-checkbox + .checkbox-label,
.themed-checkbox + .checkbox-label,
.themed-checkbox + .checkbox-label {
  font-family: "Source Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}
.themed-checkbox + .checkbox-label:before,
.themed-checkbox + .checkbox-label:before,
.themed-checkbox + .checkbox-label:before,
.themed-checkbox + .checkbox-label:before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  box-shadow: 0 0 0 1px #898d8e;
  display: inline-block;
  top: 2px;
  left: 0;
  background-color: white;
  transition: box-shadow 0.2s;
  border-radius: 2px;
}
.themed-checkbox + .checkbox-label:after,
.themed-checkbox + .checkbox-label:after,
.themed-checkbox + .checkbox-label:after,
.themed-checkbox + .checkbox-label:after {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  background-color: #6e6e6e;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' aria-hidden='true' focusable='false'%3E%3Cg %3E%3Cpolyline points='11.99 5 6.49 11 4.01 8.54' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  display: none;
  top: 2px;
  left: 0;
}
.themed-checkbox + .checkbox-label:hover:before,
.themed-checkbox + .checkbox-label:hover:before,
.themed-checkbox + .checkbox-label:hover:before,
.themed-checkbox + .checkbox-label:hover:before {
  box-shadow: 0 0 0 3px #20211f;
}
.themed-checkbox + .checkbox-label:hover:after,
.themed-checkbox + .checkbox-label:hover:after,
.themed-checkbox + .checkbox-label:hover:after,
.themed-checkbox + .checkbox-label:hover:after {
  background-color: #6e6e6e;
}

.themed-checkbox,
.themed-checkbox {
  position: absolute;
  width: 0;
  /* Mac Chrome, Safari */
  opacity: 0;
}
.themed-checkbox:focus + .checkbox-label:before,
.themed-checkbox:focus + .checkbox-label:before {
  box-shadow: 0 0 0 3px #20211f;
}
.themed-checkbox:checked + .checkbox-label:after,
.themed-checkbox:checked + .checkbox-label:after {
  display: inline-block;
}
.themed-checkbox:active + .checkbox-label:before,
.themed-checkbox:active + .checkbox-label:before {
  box-shadow: 0 0 0 1px #20211f;
  background-color: white;
}
.themed-checkbox:active + .checkbox-label:after,
.themed-checkbox:active + .checkbox-label:after {
  background-color: #20211f;
}
